<template>
  <div class="main-box">
    <div class="mg-bt-10">
      <el-button type="warning" size="small" icon="el-icon-plus" @click="addMenu()">添加日期</el-button>
    </div>
    <div class="list">
      <div class="item" v-for="(item,index) in list" :key="index">
        <div class="start">
          <el-date-picker
              v-model="item.start_year"
              format="yyyy年"
              value-format="yyyy"
              type="year"
              size="small"
              placeholder="选择开始时间">
          </el-date-picker>
        </div>
        <div class="end">
          <el-date-picker
              v-model="item.end_year"
              format="yyyy年"
              value-format="yyyy"
              type="year"
              size="small"
              placeholder="选择结束时间">
          </el-date-picker>
        </div>
        <div class="btn">
          <el-button type="text" size="small" @click="delMenu(index)">删除</el-button>
        </div>
      </div>
    </div>
    <el-button type="primary" size="small" @click="saveMenu()">保存</el-button>
  </div>
</template>
<script>

export default {
  components: {
  },
  data() {
    return {
      info: false,
      source_domain: this.config.SOURCE_DOMAIN,
      list: []
    };
  },
  created() {
    this.getInfo()
  },
  mounted() {
  },
  computed: {
  },
  methods: {
    getInfo() {
      var that = this
      that.showLoading()
      this.$api.news.newsDateInfo({}, function (res) {
        that.hideLoading()
        if(res.errcode == 0) {
          that.list = res.data
        } else {
          that.fail(res.errmsg)
        }
      })
    },
    addMenu() {
      this.list.push({
        start_year: '',
        end_year: '',
      })
    },
    delMenu(index) {
      this.list.splice(index,1)
    },
    saveMenu() {
      var that = this
      this.$api.news.newsDateSave({data: this.list},function (res) {
        if(res.errcode == 0) {
          that.success(res.errmsg)
        } else {
          that.fail(res.errmsg)
        }
      })
    }
  }
};
</script>
<style scoped>
.list {

}
.list .item {
  display: flex;
  margin-bottom: 10px;
}
.list .item>div {
  margin-right: 10px;
}
</style>
